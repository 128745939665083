<template>
  <section id="home">
    <div v-for="(image, index) in images" :key="index" class="background" :class="{ active: index === currentIndex }" :style="{ backgroundImage: `url('${image}')` }"></div>
    <div class="header">Lulu Photos Barcelona</div>
    <div class="subheader">我是常驻巴塞罗那的独立摄影师Lulu，按下的每一次快门，都是为在这座城市旅行的人们留下终生难忘的美好回忆。</div>
    <a id="btn" href="#contact">即刻预订>>></a>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      images: [
        require('@/assets/images/ciutadella_1.jpg'),
        require('@/assets/images/199861713015935.jpg'),
        require('@/assets/images/198381712845550.jpg'),
        require('@/assets/images/falda_roja.jpg'),
        require('@/assets/images/tibidabo_1.jpg')
      ],
      currentIndex: 0
    }
  },
  mounted() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }, 5000);
  }
}
</script>