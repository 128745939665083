<template>
  <section id="gallery" class="gallery">
    <div class="container">
      <div id="slide" :style="slideStyle">
        <div v-for="(item, index) in displayItems" :key="item.id" class="item" :class="{ active: index === 1 }" :style="{ backgroundImage: `url('${item.image}')` }">
          <div class="content">
            <div class="name">{{ item.name }}</div>
            <div class="des">{{ item.description }}</div>
            <button @click="goToMorePhotos">查看更多样片</button>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button id="prev" @click="prevSlide"><i class="fa-solid fa-angle-left"></i></button>
        <button id="next" @click="nextSlide"><i class="fa-solid fa-angle-right"></i></button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Gallery',
  data() {
    return {
      items: [
        { id: 1, image: require('@/assets/images/Pareja_1_Catedral.jpg'), name: '轻婚纱摄影', description: '穿着最美丽的婚纱，在最美好的时光里，牵着伴侣的手，穿行在充满艺术和浪漫气息的巴塞罗那，这样的体验仅此一份', modalId: 'casualModal' },
        { id: 2, image: require('@/assets/images/falda_roja_1.jpg'), name: '旅行拍摄', description: '不论是独自出游，或是与闺蜜，爱人，朋友结伴同行，我们都能为您拍摄最佳的旅行照片，为您留下独一无二的旅行回忆', modalId: 'travelModal' },
        { id: 3, image: require('@/assets/images/familia_1.jpg'), name: '家庭回忆', description: '与家人一起出游的时光通常是最美好也是最难得的回忆，能够举起相机见证您家庭的幸福，也能点亮我的每一天', modalId: 'familyModal' },
        { id: 4, image: require('@/assets/images/pareja_2_puente.jpg'), name: '婚礼拍摄', description: '您是否在巴塞罗那寻找最合适的婚礼摄影师？更偏好于亚洲摄影师的风格？点击查看更多样片，我们定能满足您的要求', modalId: 'weddingModal' }
      ],
      currentIndex: 0,
      direction: 0,
      transitioning: false
    }
  },
  computed: {
    displayItems() {
      const itemCount = this.items.length;
      const prev = (this.currentIndex - 1 + itemCount) % itemCount;
      const next = (this.currentIndex + 1) % itemCount;
      return [this.items[prev], this.items[this.currentIndex], this.items[next]];
    },
    slideStyle() {
      return {
        transform: `translateX(${-33.33 - this.direction * 33.33}%)`,
        transition: this.transitioning ? 'transform 0.5s' : 'none'
      };
    }
  },
  methods: {
    prevSlide() {
      if (this.transitioning) return;
      this.direction = -1;
      this.transitioning = true;
      this.triggerAnimation();
      setTimeout(() => this.finishTransition(), 500);
    },
    nextSlide() {
      if (this.transitioning) return;
      this.direction = 1;
      this.transitioning = true;
      this.triggerAnimation();
      setTimeout(() => this.finishTransition(), 500);
    },
    goToMorePhotos() {
      window.location.href = '/Morephotos/';
    },
    finishTransition() {
      this.currentIndex = (this.currentIndex + this.direction + this.items.length) % this.items.length;
      this.direction = 0;
      this.transitioning = false;
    },
    triggerAnimation() {
      // 重置动画
      const activeItem = this.$el.querySelector('.item.active');
      if (activeItem) {
        const elements = activeItem.querySelectorAll('.name, .des, button');
        elements.forEach(el => {
          el.style.animation = 'none';
          el.offsetHeight; // 触发重绘
          el.style.animation = null;
        });
      }
    },
  },
}
</script>