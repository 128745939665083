<template>
    <section id="pricing">
      <header>
        <h1>价格表
          <p>我们同时提供旅游相关咨询服务，若您需要定制包车/景区门票/接送机/游玩攻略/等服务或资讯，请在预订时告知</p>
        </h1>
      </header>
      <main>
        <div class="section price">
          <div class="price-card" v-for="(price, index) in prices" :key="index">
            <h2>{{ price.title }}</h2>
            <p v-for="(detail, detailIndex) in price.details" :key="detailIndex">{{ detail }}</p>
          </div>
        </div>
        <div class="terms">
          <h2>拍摄流程与付款</h2>
          <p v-for="(term, index) in terms" :key="index">{{ term }}</p>

        </div>
        <div class="notes">
          <h2>注意事项</h2>
          <p v-for="(note, index) in notes" :key="index">{{ note }}</p>
        </div>
      </main>
    </section>
  </template>
  
  <script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Pricing',
    data() {
      return {
        prices: [
          {
            title: '单人旅拍',
            details: [
              '2小时 精修9张 - 160€起',
              '4小时 精修15张 - 280€起',
              '8小时 精修21张 - 380€起'
            ]
          },
          {
            title: '双人/多人旅拍',
            details: [
              '2小时 精修15张 - 200€起',
              '4小时 精修21张 - 340€起',
              '8小时 精修27张 - 450€起'
            ]
          },
          {
            title: '轻婚纱拍摄',
            details: [
              '2小时 精修15张 - 260€起',
              '4小时 精修21张 - 460€起',
              '8小时 精修27张 - 650€起'
            ]
          },
          {
            title: '婚礼拍摄',
            details: [
              '4小时 精修24张 - 550€起',
              '婚礼/商业/产品/写真等',
              '定制服务请联系客服咨询价格'
            ]
          }
        ],
        terms: [
          '- 支付30%定金定档，确定拍摄时间，拍摄结束后需立即支付尾款',
          '- 摄影师会提前与您联系，确定拍摄风格，服装搭配，拍摄地点等',
          '- 拍摄完成后24小时内给底片，客户选择照片后10天内返精修图',
          '- 价格可能根据您的具体需求有所变化，请联系客服咨询',
          '- 支持欧元现金/Paypal/微信/支付宝/银行转账等支付方式'
        ],
        notes: [
          '- 定金为总价30%，需提前支付，若因甲方原因取消拍摄，定金不予退还',
          '- 拍摄价格中不包含交通/餐食/门票/妆造等额外费用，付费景点需要承担摄影师的门票费用，景点与景点之间的通勤计入到拍摄时间内',
          '- 拍摄价格不包含视频，额外的视频拍摄与剪辑价格为每分钟/50€',
          '- 我方可提供部分现有道具，配饰，服装等，如有特殊需求请提前告知',
          '- 若您需要其他的拍摄需求，如商业/产品/活动等，请联系客服咨询',
        ]
      }
    }
  }
  </script>