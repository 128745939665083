<template>
  <div id="app">
    <nav>
      <ul>
        <li class="icon"><img src="@/assets/images/luluphotos_1.png"></li>
        <li>
          <a href="#home">首页</a></li>
        <li>
          <a href="#gallery">相册</a></li>  
        <li>  
          <a href="#pricing">价格</a></li>
        <li>  
          <a href="#contact">预订</a>
        </li>
      </ul>
    </nav>

    <Home />
    <Gallery />
    <Pricing />
    <Contact />


    <div class="_social-links">
    <ul class="_links-list">
      <li class="_social-link">
        <a @click.prevent="showWeChatQR" href="#">
          <font-awesome-icon :icon="['fab', 'weixin']" />
        </a>
      </li>
      <li class="_social-link">
        <a href="https://www.instagram.com/lulu.photobcn?igsh=MTVqOXI5cGUzcWdw&utm_source=qr" target="_blank">
          <font-awesome-icon :icon="['fab', 'instagram']" />
        </a>
      </li>
      <li class="_social-link">
        <a href="https://www.tiktok.com/@lulu.photobcn" target="_blank">
          <font-awesome-icon :icon="['fab', 'tiktok']" />
        </a>
      </li>
      <li class="_social-link">
        <a href="https://www.xiaohongshu.com/user/profile/613c810c0000000002024e47" target="_blank">
          <img :src="require('@/assets/images/xiaohongshu.png')" class="xiaohongshu-icon" />
        </a>
      </li>
    </ul>
    </div>

    <div v-if="showQRModal" class="qr-modal" @click="closeQRModal">
      <div class="qr-content" @click.stop>
        <img src="@/assets/images/wechat-qr.jpg" alt="WeChat QR Code">
        <button class="close-btn" @click="closeQRModal">&times;</button>
      </div>
    </div>
  </div>
</template>

<script>
import Home from './components/Home.vue'
import Gallery from './components/Gallery.vue'
import Pricing from './components/Pricing.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    Home,
    Gallery,
    Pricing,
    Contact
  },
  data() {
    return {
      showQRModal: false
    }
  },
  methods: {
    showWeChatQR() {
      this.showQRModal = true
    },
    closeQRModal() {
      this.showQRModal = false
    }
  }
}
</script>

<style src="@/assets/lulu.css">
</style>