<template>
    <section id="contact">
      <div class="contact">
        <h1>预订</h1>
        <p v-for="(contact, index) in contacts" :key="index">{{ contact }}</p>
        <p>在社交媒体上关注我</p>
        <p>获取更多巴塞罗那旅拍推荐>>></p>
      </div>
      <footer>
        © 2024 Lulu Photos Barcelona. All rights reserved.
      </footer>
    </section>
  </template>
  
  <script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Contact',
    data() {
      return {
        contacts: [
          '微信: Luluna_34',
          '小红书: 西班牙摄影师Lulu',
          '邮箱: Luluphotosbcn@gmail.com',
          '抖音: 西班牙摄影师Lulu'
        ]
      }
    }
  }
  </script>