import { createApp } from 'vue'
import App from './App.vue'
import './assets/lulu.css'

createApp(App).mount('#app')

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF, faInstagram, faTiktok, faWeixin } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebookF, faInstagram, faTiktok, faWeixin)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')

